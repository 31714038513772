<template>
  <div :class="containerStyle">
    <video ref="media" @ended="playing = false" width="100%">
      <source type="video/mp4" src="@/assets/videos/article.mp4" />
      <slot />
      <p>Your browser doesn't support HTML5 video.</p>
    </video>
    <!-- Video -->
    <div
      :class="coverStyle"
      :style="{
        background: coverBackground,
        opacity: coverOpacity,
      }"
      @mouseenter="animateActive"
      @mouseleave="animateNormal"
      @click="playVideo()"
    >
      <img
        :style="{ background: iconOpacity }"
        class="play-icon"
        src="@/assets/images/play_icon.webp"
      />
    </div>
    <div class="caption" :style="{ color: myColors.onBackground }">
      <p>Article page on desktop</p>
    </div>
    <!-- Cover -->
  </div>
</template>

<script>
import Vue from "vue";
import VueCoreVideoPlayer from "vue-core-video-player";
import PlayIcon from "@/components/svg/PlayIcon.vue";
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";

Vue.use(VueCoreVideoPlayer);
export default {
  props: {
    source: {
      type: String,
      default: "@/assets/videos/article.mp4",
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      coverBackground: "rgba(83, 82, 80, 0.5)",
      iconOpacity: 0.8,
      coverOpacity: 1,
      playing: this.isPlaying,
    };
  },
  watch: {
    playing: function(newState, oldState) {
      if (newState == true) {
        this.coverOpacity = 0;
      } else {
        this.coverOpacity = 1;
      }
    },
  },
  computed: {
    containerStyle: function() {
      return window.innerWidth < 480
        ? "player-container-mobile"
        : "player-container";
    },
    coverStyle: function() {
      return window.innerWidth < 480 ? "player-cover-mobile" : "player-cover";
    },
    isDark: function() {
      return this.$store.getters.isDark;
    },
    myColors: function() {
      return colors(this.$store.getters.isDark, this.$store);
    },
  },
  methods: {
    animateActive: function() {
      this.coverBackground = "rgba(3, 29, 96, 0.5)";
      this.iconOpacity = 1;
    },
    animateNormal: function() {
      this.coverBackground = "rgba(83, 82, 80, 0.5)";
      this.iconOpacity = 0.8;
      console.log("done");
    },
    endVideo: function() {
      this.playing = true;
    },
    playVideo: function() {
      this.coverOpacity = 0;
      this.playing = true;
      this.$refs.media.play();
    },
  },
};
</script>

<style>
.player-container-mobile {
  padding: 0 !important;
  display: grid !important;
  place-items: center;
  place-content: center;
}
.player-container {
  grid-template-columns: auto;
  display: grid;
}
.caption {
  place-self: center;
}
video {
  width: 100%;
}
.player-cover-mobile {
  width: 100%;
  height: 19.6rem;
  top: 285rem;
}
</style>
