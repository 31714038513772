<template>
  <svg
    class="play-icon"
    width="113"
    height="126"
    viewBox="0 0 113 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.8166 56.8084C105.089 59.9902 104.94 67.6868 99.5486 70.6622L20.6291 114.213C15.2372 117.189 8.64623 113.212 8.76533 107.054L10.5087 16.9326C10.6278 10.7753 17.3676 7.05597 22.6404 10.2377L99.8166 56.8084Z"
      stroke="#FFFDF7"
      stroke-width="16"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.play-icon {
  width: 20rem;
  height: 20rem;
}
</style>